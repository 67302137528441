.gray-scale {
  filter: grayscale(100%);
}
.gray-scale:hover {
  filter: grayscale(0);
}
.highlight {
  background: #daed1a;
  color: #000;
}
.btn {
  align-items: center;
  border-radius: 5px;
  display: inline-flex;
  font-size: 16px;
  height: 50px;
  justify-content: center;
  overflow: hidden;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease;
  width: 200px;
  z-index: 1;
}
.btn:after {
  transform: rotate(-30deg);
}
.btn:hover {
  transform: scale(1.05);
}
.btn:after {
  background: linear-gradient(#fff, #0000);
  content: '';
  height: 120%;
  left: -10%;
  opacity: 0.6;
  pointer-events: none;
  position: absolute;
  top: -50%;
  transform: rotate(-10deg);
  width: 120%;
}
.bg-whatsapp {
  background-color: #25d366;
  color: #fff;
}
.bg-telegram {
  background-color: #08c;
  color: #fff;
}
.bg-email {
  background-color: #d44638;
  color: #fff;
}
@media (max-width: 768px) {
  .tools-align-center {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

.backgroundImage {
  background: url('/public/assets/images/blockchain-background/web-three-bg.jpg')
    no-repeat center center/cover;
}

.wideLine {
  background: url('/public/assets/images/blockchain-background/wide-line.svg')
    no-repeat bottom;
}

.pb-120 {
  padding-bottom: 120px;
}

.highlightText {
  position: relative;
  z-index: 30;
}

.highlightUnderline {
  position: absolute;
  bottom: 1px;
  right: 0;
}

.projectBackground1 {
  background: url('/public/assets/images/core/security-tokens-t-rex-erc3643.webp')
    no-repeat center center/cover;
}

.projectBackground2 {
  background: url('/public/assets/images/core/erc-6960-house-shared-ownership.webp')
    no-repeat center center/cover;
}

.projectBackground3 {
  background: url('/public/assets/images/core/erc-20-evm-inline-assembly-yul.webp')
    no-repeat center center/cover;
}

.projectBackground4 {
  background: url('/public/assets/images/core/staking-platform-erc20-solidity.webp')
    no-repeat center center/cover;
}

.projectBackground5 {
  background: url('/public/assets/images/core/erc1155-evm-yul-assembly.webp')
    no-repeat center center/cover;
}

.tool-item {
  transition: transform 0.4s ease;
}

.tool-item:hover {
  transform: scale(1.3);
}
